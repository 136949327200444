<template>
	<div>
		<v-row>
			<v-col>
				<v-sheet height="800">
					<v-calendar
							ref="calendar"
							v-model="value"
							color="primary"
							type="day"
							hide-header
							:events="events"
							:event-color="getEventColor"
							:event-ripple="false"
							:interval-format="intervalFormatter"
							:interval-minutes="5"
							:first-interval="131"
							:interval-count="130"
							:interval-height="15"
							:locale="'ru-RU'"
							:event-name="'name'"
							:weekdays="[1, 2, 3, 4, 5, 6, 0]"

							@change="getEvents"
							@mousedown:event="startDrag"
							@mousedown:time="startTime"
							@mousemove:time="mouseMove"
							@mouseup:time="endDrag"
							@mouseleave.native="cancelDrag"
					>
						<template v-slot:event="{ event, timed, eventSummary }">
							<div
									class="v-event-draggable"
									v-html="eventSummary()"
							></div>
							{{ eventSummary() }}
							<div
									v-if="timed"
									class="v-event-drag-bottom"
									@mousedown.stop="extendBottom(event)"
							></div>
						</template>
						<template v-slot:day-body="{ date, week }">
							<div
									class="v-current-time"
									:class="{ first: date === week[0].date }"
									:style="{ top: nowY }"
							></div>
						</template>
					</v-calendar>
				</v-sheet>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import _ from 'lodash'
import httpClient from '@/services/http.service'
export default {
  name: 'Schedule',
  props:{
    product: Object,
    finalLink: String,
    postId: Number
  },
  data(){
    return{
      ready: false,
      dialog: false,
      schedule: [],
      preEvent: {},
      postAt: '',
      value: '',
      events: [],
      colors: ['#2196F3', '#3F51B5', '#673AB7', '#00BCD4', '#4CAF50', '#FF9800', '#757575'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      eventMoving: null,
    }
  },
  created () {
    this.loadSchedule()
  },
  mounted () {
    this.ready = true
    this.scrollToTime()
    this.updateTime()
  },
  computed: {
    cal () {
      return this.ready ? this.$refs.calendar : null
    },
    nowY () {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    },
    needReloadScheduleEvents(){
      return this.$store.state.needReloadScheduleEvents
    }
  },
  watch:{
    needReloadScheduleEvents(v){
      if (v){
        this.loadSchedule()
      }
    }
  },
  methods:{
    loadSchedule(){
      this.$store.dispatch('getSchedule', true).then((data)=>{
        console.log('schedule: ', data)
        this.schedule = data
        this.parseSchedule()
        this.$store.dispatch('needReloadScheduleEvents', false)
      })
    },
    updateTimes(start, pid){
      httpClient.post(`posts/update-time?pid=${pid}&planned_at=${start}`)
    },
    createEvents(){
      this.events = [{start: 1630989000000, end: 1630989300000, color:'#FF9800', timed: true, name: 'Ивент 1'}, {start: 1630992600000, end: 1630992900000, color:'#FF9800', timed: true, name: 'Умуте № 2'}]
      this.parseSchedule()
    },
    parseSchedule(){
      _.each(this.schedule, (post, i) =>{
        console.log(post)
        let color
        if (post.status === 'Запланирован'){
          color = 'info'
        }else if(post.status === 'Опубликован'){
          color = 'success'
        }else{
          color = 'warning'
        }
        this.events.push({start: Date.parse(post.planned_at), end: Number(Date.parse(post.planned_at)) + 300000, timed: true, name: post.product.title, color: color, pid: post.pid})
      })
    },
    addPreEvent(){
      this.postAt = this.preEvent.start
      this.events.push(this.preEvent)
      this.dialog = false
      this.preEvent = {}
      this.post()
    },
    startDrag({ event, timed }) {
      if (event.status !== 'Запланирован'){
        return false
      }
      this.eventMoving = event
      console.log('start drag, event: ', event, timed)
      if (event && timed) {
        this.dragEvent = event
        this.dragTime = null
        this.extendOriginal = null
      }
      return true
    },
    startTime(tms) {
      console.log('start time')
      const mouse = this.toTime(tms)
      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start
        this.dragTime = mouse - start
      } else {
        this.createStart = this.roundTime(mouse)
        this.preEvent = {start: this.createStart, end: Number(this.preEvent) + 300000, timed: true, name: this.product.title, color:'#00cae3'}
        this.createEvent = {
          name: `Event #${this.events.length}`,
          color: this.rndElement(this.colors),
          start: this.createStart,
          end: this.createStart,
          timed: true,
        }
        // this.events.push(this.createEvent)
      }
    },
    extendBottom(event) {
      this.createEvent = event
      this.createStart = event.start
      this.extendOriginal = event.end
    },
    mouseMove (tms) {
      const mouse = this.toTime(tms)
      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start
        const end = this.dragEvent.end
        const duration = end - start
        const newStartTime = mouse - this.dragTime
        const newStart = this.roundTime(newStartTime)
        const newEnd = newStart + duration

        this.dragEvent.start = newStart
        this.dragEvent.end = newEnd
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false)
        const min = Math.min(mouseRounded, this.createStart)
        const max = Math.max(mouseRounded, this.createStart)

        this.createEvent.start = min
        this.createEvent.end = max
      }
    },
    endDrag(date) {
      console.log('end drag! date: ', date, ', event: ', this.eventMoving)
      this.updateTimes(Number(this.eventMoving.start/1000), this.eventMoving.pid)
      this.eventMoving = null
      this.dragTime = null
      this.dragEvent = null
      this.createEvent = null
      this.createStart = null
      this.extendOriginal = null
      // this.dialog = true
    },
    cancelDrag () {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal
        } else {
          const i = this.events.indexOf(this.createEvent)
          if (i !== -1) {
            this.events.splice(i, 1)
          }
        }
      }

      this.createEvent = null
      this.createStart = null
      this.dragTime = null
      this.dragEvent = null
    },
    roundTime (time, down = true) {
      const roundTo = 5 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
        ? time - time % roundDownTime
        : time + (roundDownTime - (time % roundDownTime))
    },
    toTime (tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },
    getEventColor (event) {
      const rgb = parseInt(event.color.substring(1), 16)
      const r = (rgb >> 16) & 0xFF
      const g = (rgb >> 8) & 0xFF
      const b = (rgb >> 0) & 0xFF

      return event === this.dragEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event === this.createEvent
          ? `rgba(${r}, ${g}, ${b}, 0.7)`
          : event.color
    },
    getEvents ({ start, end }) {
      // const events = []
      //
      // const min = new Date(`${start.date}T00:00:00`).getTime()
      // const max = new Date(`${end.date}T23:59:59`).getTime()
      // const days = (max - min) / 86400000
      // const eventCount = this.rnd(days, days + 20)
      //
      // for (let i = 0; i < eventCount; i++) {
      // 	const timed = this.rnd(0, 3) !== 0
      // 	const firstTimestamp = this.rnd(min, max)
      // 	const secondTimestamp = this.rnd(2, timed ? 8 : 288) * 900000
      // 	const start = firstTimestamp - (firstTimestamp % 900000)
      // 	const end = start + secondTimestamp
      //
      // 	events.push({
      // 		name: this.rndElement(this.names),
      // 		color: this.rndElement(this.colors),
      // 		start,
      // 		end,
      // 		timed,
      // 	})
      // }
      //
      // this.events = events
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    rndElement (arr) {
      return arr[this.rnd(0, arr.length - 1)]
    },
    getCurrentTime () {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
    },
    scrollToTime () {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 30)

      this.cal.scrollToTime(first)
    },
    updateTime () {
      setInterval(() => this.cal.updateTimes(), 60 * 1000)
    },
    intervalFormatter(locale, getOptions) {
      return locale.time
    },
  }
}
</script>

<style lang="scss">
	.v-event-draggable {
		padding-left: 6px;
	}

	.v-event-timed {
		user-select: none;
		-webkit-user-select: none;
	}

	.v-event-drag-bottom {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 4px;
		height: 4px;
		cursor: ns-resize;

		&::after {
			display: none;
			position: absolute;
			left: 50%;
			height: 4px;
			border-top: 1px solid white;
			border-bottom: 1px solid white;
			width: 16px;
			margin-left: -8px;
			opacity: 0.8;
			content: '';
		}

		&:hover::after {
			display: block;
		}
	}
	.v-current-time {
		height: 2px;
		background-color: #ea4335;
		position: absolute;
		left: -1px;
		right: 0;
		pointer-events: none;

		&.first::before {
			content: '';
			position: absolute;
			background-color: #ea4335;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			margin-top: -5px;
			margin-left: -6.5px;
		}
	}
	button .transparent{
		background-color: #00cae3 !important;
	}
</style>
