var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-sheet',{attrs:{"height":"800"}},[_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","type":"day","hide-header":"","events":_vm.events,"event-color":_vm.getEventColor,"event-ripple":false,"interval-format":_vm.intervalFormatter,"interval-minutes":5,"first-interval":131,"interval-count":130,"interval-height":15,"locale":'ru-RU',"event-name":'name',"weekdays":[1, 2, 3, 4, 5, 6, 0]},on:{"change":_vm.getEvents,"mousedown:event":_vm.startDrag,"mousedown:time":_vm.startTime,"mousemove:time":_vm.mouseMove,"mouseup:time":_vm.endDrag},nativeOn:{"mouseleave":function($event){return _vm.cancelDrag.apply(null, arguments)}},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var event = ref.event;
var timed = ref.timed;
var eventSummary = ref.eventSummary;
return [_c('div',{staticClass:"v-event-draggable",domProps:{"innerHTML":_vm._s(eventSummary())}}),_vm._v(" "+_vm._s(eventSummary())+" "),(timed)?_c('div',{staticClass:"v-event-drag-bottom",on:{"mousedown":function($event){$event.stopPropagation();return _vm.extendBottom(event)}}}):_vm._e()]}},{key:"day-body",fn:function(ref){
var date = ref.date;
var week = ref.week;
return [_c('div',{staticClass:"v-current-time",class:{ first: date === week[0].date },style:({ top: _vm.nowY })})]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }